@import '../../../../scss/helpers/modular-typography.module.scss';

.fieldset {
    border: none;
    padding: 0;

    &__legend {
        @include h4();
        color: #1976d2;
        margin-bottom: $blh * 1rem;
    }
}