@import '../../../../scss/variables/typography.module.scss';

.buttonsWrapper {
    text-align: right;

    button:first-of-type {
        margin-right: 10px;
    }
}

.addButtonWrapper,
.buttonsWrapper,
.errorWrapper {
    margin-bottom: 1rem * $blh;
}
