@import '../../scss/variables/typography.module.scss';
@import '../../scss/helpers/responsive-breakpoints.module.scss';

.orderDetails {
    &__orderNo {
        margin-bottom: $blh * 1rem;
    }

    &__transactions {
        width: 90vw;
        margin-left: calc((100% - 90vw) / 2); // Remove the gap to the left of the container
    }
}

.infoBlockWrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: $blh * 1.5rem;

    @include screen-max(1000px) {
        display: flex;
        flex-direction: column;
        width: 90%;
    }

    &__commentWrapper {
        margin-left: $blh * 2rem;
        width: 90%;
    }

    @include screen-max(1000px) {
        &__commentWrapper {
            margin-left: 0;
        }
    }
}

.blocksWrapper {
    display: flex;
    flex-direction: row;

    &__orderInfo {
        width: 20vw;
    }

    &__balanceInfo {
        width: 20vw;
    }

    @include screen-max(1000px) {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__orderInfo {
            margin-bottom: $blh * 1.5rem;
            width: 90%;
        }

        &__balanceInfo {
            width: 90%;
        }
    }


}
