@import '../../../../../../scss/variables/typography.module.scss';

.errorWrapper,
.transactionDesc {
    margin-bottom: $blh * 1rem;
}

.buttonWrapper {
    margin-bottom: $blh * 0.5rem;
}
