@import '../../../../scss/helpers/responsive-breakpoints.module.scss';

$breakpoint-sm: 800px;
$breakpoint-md: 1400px;


.flexContainer {
    @include screen-min($breakpoint-sm) {
        display: flex;
    }

    @include screen-min($breakpoint-md) {
        display: block;
    }

    &__item {
        @include screen-min($breakpoint-sm) {
            flex-basis: 250px;
            margin-right: 20px;
        }

        @include screen-min($breakpoint-md) {
            display: flex;
        }

        > div {
            @include screen-min($breakpoint-md) {
                width: 250px;
                margin-right: 20px;
            }
        }
    }
}
