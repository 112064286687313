@import '../../scss/variables/typography.module.scss';

.wipAlert,
.errorWrapper,
.textWrapper {
    margin-bottom: $blh * 1rem;
}

.form {
    max-width: 250px;
}