@import '../../scss/variables/typography.module.scss';

.alertWrapper {
    margin-bottom: $blh * 0.5rem;
}

.actionMenu {
    text-align: right;
    margin-bottom: $blh * 0.5rem;
}

.errorWrapper {
    margin-bottom: 1rem * $blh;
}

.params {
    margin-bottom: $blh * 1rem;
}