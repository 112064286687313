@import '../../scss/variables/typography.module.scss';

.paper {
    min-width: 300px;
    max-width: 400px;
    padding: 20px;
    margin: 0 auto;
    box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14),
        0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.errorWrapper {
    margin-top: $blh + 1rem;
}

.headingWrapper {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
}

.avatar {
    background-color: #000;
    margin-right: 12px;
}