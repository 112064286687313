@import '../../../../scss/variables/typography.module.scss';
@import '../../../../scss/helpers/responsive-breakpoints.module.scss';

.commentWrapper {
    margin-top: $blh * 2rem;
    margin-bottom: $blh * 0.5rem;
}

.postBtnWrapper {
    text-align: right;
}