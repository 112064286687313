@import '../../scss/variables/typography.module.scss';
@import '../../scss/helpers/responsive-breakpoints.module.scss';

.alertWrapper {
    margin-bottom: $blh * 0.5rem;
}

.menuWrapper {
    text-align: right;
    margin-bottom: $blh * 0.5rem;
}

.errorWrapper {
    margin-bottom: $blh * 1rem;
}

.tableWrapper {
    $breakpoint: 800px; 
    margin-bottom: $blh * 2rem;

    @include screen-min($breakpoint) {
        display: flex;
        flex-wrap: nowrap;
    }
 
    &__table {
        @include screen-max($breakpoint) {
            max-width: 400px;
        }
        @include screen-min($breakpoint) {
            flex: 300px 0 0;
        }

        &:first-of-type {
            @include screen-max($breakpoint) {
                margin-bottom: $blh * 1rem;
            }

            @include screen-min($breakpoint) {
                margin-right: 40px;
            }
        }

        th {
            width: 150px;
        }
    }
}