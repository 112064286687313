@import '../../../../../../scss/variables/colors.module.scss';
@import '../../../../../../scss/helpers/responsive-breakpoints.module.scss';

$breakpoint: 1400px;

.gridFooter {
    font-size: 14px;
    padding: 10px 0 5px 10px;
    border-top: 1px solid $primary;
    font-weight: bold;

    @include screen-min($breakpoint) {
        display: flex;
    }

    &__mobileRow {
        @include screen-min($breakpoint) {
            display: none;
        }
    }

    &__mobileHeading {
        display: inline-block;
        width: 80px;
    } 

    &__heading {
        display: none;

        @include screen-min($breakpoint) {
            display: block;
            margin-right: 1070px; // magic value
        }
    }

    &__total {
        display: none; 

        @include screen-min($breakpoint) {
            display: inline-block;
            width: 100px; // magic number (equals the column width)
        }
    }

    &__totalUnbalanced {
        color: $error-dark;
    }

    &__totalBalanced {
        color: $success-dark;
    }
}

.gridFooterSelected {
    font-size: 14px;
    padding: 5px 0 10px 10px;
    font-weight: bold;

    @include screen-min($breakpoint) {
        display: flex;
    }

    &__mobileRow {
        @include screen-min($breakpoint) {
            display: none;
        }
    }

    &__mobileHeading {
        display: inline-block;
        width: 135px;
    } 

    &__heading {
        display: none;

        @include screen-min($breakpoint) {
            display: block;
            width: 150px;
            margin-right: 962px; // magic number
        }
    }

    &__total {
        display: none; 

        @include screen-min($breakpoint) {
            display: inline-block;
            width: 100px; // magic number (equals the column width)
        }
    }

    &__totalUnbalanced {
        color: $error-dark;
    }

    &__totalBalanced {
        color: $success-dark;
    }
}