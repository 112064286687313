@import '../../../../scss/variables/typography.module.scss';
@import '../../../../scss/helpers/responsive-breakpoints.module';

$breakpoint: 600px;
$input-width: 250px;

.textInput,
.dateInput,
.numberInput,
.selectInput {
  margin-bottom: $blh * 1rem;

  @include screen-min($breakpoint) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__label {
    display: none;

    @include screen-min($breakpoint) {
      display: block;
    }
  }

  &__inputWrapper {
    @include screen-min($breakpoint) {
      flex: $input-width 0 0;
    }
  }
}

.textareaInput {
  &__inputWrapper {
    margin-bottom: $blh * 0.5rem;
  }
}

.checkboxGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $blh * 1rem;

  &__inputWrapper {
      label:last-child {
          margin-right: 2px;
      }
  }
}