@import '../../../../scss/helpers/responsive-breakpoints.module.scss';

.params {
    $breakpoint: 400px; 

    @include screen-min($breakpoint) {
        display: flex;
        justify-content: end;
    }

    &__param {
        @include screen-min($breakpoint) {
        flex: 250px 0 0;
        margin-right: 20px;
        }
    }
}