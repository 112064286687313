@import '../../scss/variables/typography.module.scss';

.contentWrapper {
    flex: 1 1 auto; // Span the entire remaining height of flex parent
    // Center content vertically
    display: flex;
    justify-content: center;
    align-items: center;
    
    &__text {
        margin-bottom: $blh * 1rem;
    }
}