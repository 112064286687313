@import '../../../../scss/variables/typography.module.scss';
@import '../../../../scss/helpers/responsive-breakpoints.module.scss';

$breakpoint-xs: 480px; 
$breakpoint-md: 1200px; 
$flex-basis: 250px;
$right-margin: 20px;

.outerFlexContainer {
  @include screen-min($breakpoint-xs) {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    @include screen-min($breakpoint-xs) {
      flex: $flex-basis 0 0;
    }

    @include screen-min($breakpoint-md) {
      flex: calc(2 * $flex-basis + $right-margin ) 0 0;
    }
  }

  &__item:first-child {
    @include screen-min($breakpoint-xs) {
      margin-right: $right-margin;
    }
  }

}

.innerFlexContainer {
  @include screen-min($breakpoint-md) {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    @include screen-min($breakpoint-md) {
      flex: $flex-basis 0 0;
    }
  }

  &__item:first-child {
    @include screen-min($breakpoint-md) {
      margin-right: $right-margin;
    }
  }
}